<template>
<div class="block block--psmall">
  <div class="width__maxbody flex instagram__text">
    <h3 class="heading--small text--purple instagram__header">{{ data.heading }}</h3>
    <p class="instagram__description text--gray" v-html="content"></p>
  </div>
  <div class="instagram__grid mt-4">
    <template v-if="images && images.length > 0">
      <a class="instagram__item" v-for="image in images" :key="image.id" :href="image.permalink" target="_blank">
        <img :src="image.mediaType === 'IMAGE' ? image.originalUrl : image.thumbnailUrl"/>
      </a>
    </template>
    <template v-else>
      <div class="instagram__item placeholder"></div>
      <div class="instagram__item placeholder"></div>
      <div class="instagram__item placeholder"></div>
      <div class="instagram__item placeholder"></div>
      <div class="instagram__item placeholder"></div>
    </template>

    <!-- <div class="instagram__item">
      <img src="/insta_1.jpg"/>
    </div>
    <div class="instagram__item">
      <img src="/insta_2.png"/>
    </div>
    <div class="instagram__item">
      <img src="/insta_3.png"/>
    </div>
    <div class="instagram__item">
      <img src="/insta_4.png"/>
    </div>
    <div class="instagram__item">
      <img src="/insta_5.png"/>
    </div> -->
  </div>
</div>
</template>

<script lang="ts" setup>
import useStrapiHelper from "~~/composables/strapi";

const { flattenObject } = useStrapiHelper();
const { find } = useStrapi();
const client = useStrapiClient();

const { data } = await useAsyncData(
  'instagram-block',
  () => find('instagram-block'),
  {
    transform: (data) => {
      return flattenObject(data);
    }
  }
);

const { data: images } = await useAsyncData(
  'instagram-images',
  () => client('instagram/images?sort[0]=timestamp:desc', { method: 'GET' }),
  {
    transform: (data) => {
      if (!data || data.results?.length <= 0) return [];
      const items = data.results
        // Filter out different items from same post
        .filter((value, index, self) => self.map(item => item.mediaId).lastIndexOf(value.mediaId) === index);
        // Sort on date - reversed
        // .sort((imageA, imageB) => { return (new Date(imageB.timestamp)).getTime() - (new Date(imageA.timestamp)).getTime() });

      return items.length > 5 ? items.slice(0, 5) : items;
    }
  }
)

const content = computed(() => JSON.parse(data.value.text).blocks.map(block => block.data.text).join('<br/>'))
</script>

<style lang="scss">
.instagram__text {
  @include breakpoint(null, $breakpoint_sm) {
    flex-direction: column;
  }
}

.instagram__header {
  width: 11rem;

  @include breakpoint(null, $breakpoint_sm) {
    width: 100%;
  }
}

.instagram__description {
  margin-left: 6rem;
  max-width: 24rem;

  @include breakpoint(null, $breakpoint_sm) {
    margin-left: 0;
    margin-top: 2rem;
    width: 100%;
  }
}

.instagram__grid {
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  gap: 1rem;
  padding: 0 0.5rem;
  max-width: 1920px;
  margin-left: auto;
  margin-right: auto;

  @include breakpoint(null, $breakpoint_sm) {
    grid-template-columns: repeat(4, 1fr);
    gap: 0.25rem;
    padding: 0;
  }
}

.instagram__item {
  aspect-ratio: 1 / 1;
  display: block;

  &.placeholder {
    background: rgb(209, 209, 209);
  }

  @include breakpoint(null, $breakpoint_sm) {
    &:last-child {
      display: none;
    }
  }

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: center;
  }
}
</style>
