<template>
<div class="block block--red">
  <Ball class="ball--purple ball--big cta__ball"/>
  <div class="width__maxbody flex cross-center ill-container">
    <WonderedIllustration class="ill--wondered"/>
    <div class="cta__leftpadded">
      <h3 class="heading--medium">{{ data.heading }}</h3>
      <p class="mt-2 narrowed-paragraph">{{ data.text }}</p>
    </div>
    <ButtonLink class="btn--white shrink-0 ml-3" :link="data.button.link">
      {{ data.button.label }}
      <Icon name="pepicons:arrow-right" class="btn__icon btn__icon--small btn__icon--right"/>
    </ButtonLink>
  </div>
</div>
</template>

<script lang="ts" setup>
import WonderedIllustration from '~/assets/svg/wondered.svg?component';
import useStrapiHelper from "~~/composables/strapi";

const { flattenObject } = useStrapiHelper();
const { find } = useStrapi();

const { data } = await useAsyncData(
  'cta-block',
  () => find('call-to-action-block', { populate: ['button'] }),
  {
    transform: (data) => {
      return flattenObject(data);
    }
  }
);
</script>

<style lang="scss">
.cta__leftpadded {
  margin-left: 17rem;

  @include breakpoint(null, $breakpoint_sm) {
    margin-left: 0;
  }
}

.ill-container {
  position: relative;

  @include breakpoint(null, $breakpoint_md) {
    flex-direction: column;
    align-items: flex-start;
    gap: 4rem;

    .ml-3 {
      margin-left: 17rem;
    }
  }

  @include breakpoint(null, $breakpoint_sm) {
    padding-bottom: 10rem;

    .ml-3 {
      margin-left: 0;
    }
  }
}

.ill--wondered {
  position: absolute;
  left: -10rem;
  bottom: -8.7rem;
  width: 380px;
  height: auto;

  @include breakpoint(null, $breakpoint_md) {
    left: 1.5rem;
    width: 250px;
    bottom: -7.7rem;
  }
}

.cta__ball {
  position: absolute;
  left: -8rem;
  bottom: -8rem;

  @include breakpoint(null, 1760px) {
    display: none;
  }
}
</style>
